<template>
  <b-form
    v-if="elementData.follow_date_4 !== undefined && elementData.services">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.action_plan')
            }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="antiretroviral_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.antiretroviral')
                "
                :element-selection-list="antiretroviralList"
                :rowly="true"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row v-if="elementData.services">
            <b-col cols="3">
              <feathebooleanListr-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold left_title">{{
                $t(consultResourcesElementName + '.object.scheme')
              }}</span
              ><span
                v-if="!disabled"
                class="text-danger"
                >*</span
              >
            </b-col>
            <b-col
              cols="9"
              md="9">
              <edit-medicines
                :element-data="elementData"
                :medicines-list="medicinesList" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="medical_follow_vih_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.medicalFollowVih')
                "
                :element-selection-list="medicalFollowVihList"
                :rowly="true"
                :exec="onFollowChange"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="medical_follow_vhc_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.medicalFollowVhc')
                "
                :element-selection-list="medicalFollowVhcList"
                :rowly="true"
                :exec="onFollowChange"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="medical_follow_syphilis_id"
                :root-element-data="elementData"
                :element-label="
                  $t(
                    consultResourcesElementName +
                      '.object.medicalFollowSyphilis'
                  )
                "
                :element-selection-list="medicalFollowSyphilisList"
                :rowly="true"
                :exec="onFollowChange"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="vaccine_hepatitis_b_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.vaccineHepatitisB')
                "
                :element-selection-list="vaccineHepatitisBList"
                :rowly="true"
                :exec="onFollowChange"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="medical_follow_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.medicalFollow')
                "
                :element-selection-list="medicalFollowList"
                :rowly="true"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="severity_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.severityList')
                "
                :element-selection-list="severityList"
                :rowly="true"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="origin_classification_id"
                :root-element-data="elementData"
                :element-label="
                  $t(
                    consultResourcesElementName +
                      '.object.originClassificationList'
                  )
                "
                :element-selection-list="originClassificationPatList"
                :rowly="true"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="element-averse">
                <b-form-checkbox
                  v-model="elementData.adverse_effect"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled" />
                <span class="ml-1 font-weight-bold info_title">{{
                  $t(consultResourcesElementName + '.object.adverse_effect')
                }}</span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="element-non-result">
                <b-form-checkbox
                  v-model="elementData.non_result_until_now"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled" />
                <span class="ml-1 font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName + '.object.non_result_until_now'
                  )
                }}</span>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="3"
              md="3"
              lg="3">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.follow_dates')
              }}</span>
            </b-col>
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              md="4"
              lg="4">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.follow_1')
              }}</span>
              <date-picker
                size="lg"
                :element-required="false"
                element-field="follow_date_1"
                :root-element-data="elementData"
                element-label=""
                :disabled="disabled" />
            </b-col>
            <b-col
              cols="4"
              md="4"
              lg="4">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.follow_2')
              }}</span>
              <date-picker
                size="lg"
                :element-required="false"
                element-field="follow_date_2"
                :root-element-data="elementData"
                element-label=""
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="3"
              md="3"
              lg="3" />
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              md="4"
              lg="4">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.follow_3')
              }}</span>
              <date-picker
                size="lg"
                :element-required="false"
                element-field="follow_date_3"
                :root-element-data="elementData"
                element-label=""
                :disabled="disabled" />
            </b-col>
            <b-col
              cols="4"
              md="4"
              lg="4">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.follow_4')
              }}</span>
              <date-picker
                size="lg"
                :element-required="false"
                element-field="follow_date_4"
                :root-element-data="elementData"
                element-label=""
                :disabled="disabled" />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import moment from 'moment'
import EditMedicines from './services/EditMedicines.vue'

export default {
  components: {
    EditMedicines,
    RadioSelectPicker,
    BFormCheckbox,
    DatePicker,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    medicinesList: {
      type: Array,
      required: true,
    },
    antiretroviralList: {
      type: Array,
      required: true,
    },
    severityList: {
      type: Array,
      required: true,
    },
    medicalFollowList: {
      type: Array,
      required: true,
    },
    vaccineHepatitisBList: {
      type: Array,
      required: true,
    },
    medicalFollowSyphilisList: {
      type: Array,
      required: true,
    },
    medicalFollowVhcList: {
      type: Array,
      required: true,
    },
    medicalFollowVihList: {
      type: Array,
      required: true,
    },
    originClassificationPatList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      resourcesElementName: 'case',
      consultResourcesElementName: 'consult',
    }
  },
  mounted() {
    this.onFollowChange()
  },
  methods: {
    onFollowChange() {
      if (!this.elementData.services) {
        this.elementData.services = []
      }
      let medical_follow_vih = this.medicalFollowVihList.find(
        (element) => element.id == this.elementData.medical_follow_vih_id
      )
      let medical_follow_vhc = this.medicalFollowVhcList.find(
        (element) => element.id == this.elementData.medical_follow_vhc_id
      )
      let medical_follow_syphilis = this.medicalFollowSyphilisList.find(
        (element) => element.id == this.elementData.medical_follow_syphilis_id
      )
      let vaccine_hepatitis_b = this.vaccineHepatitisBList.find(
        (element) => element.id == this.elementData.vaccine_hepatitis_b_id
      )

      medical_follow_vih = medical_follow_vih ? medical_follow_vih.key : ''
      medical_follow_vhc = medical_follow_vhc ? medical_follow_vhc.key : ''
      medical_follow_syphilis = medical_follow_syphilis
        ? medical_follow_syphilis.key
        : ''
      vaccine_hepatitis_b = vaccine_hepatitis_b ? vaccine_hepatitis_b.key : ''

      this.elementData.close_hiv = medical_follow_vih == 'NONE' ? '1' : '0'
      this.elementData.close_vhc = medical_follow_vhc == 'NONE' ? '1' : '0'
      this.elementData.close_vhb = vaccine_hepatitis_b == 'NONE' ? '1' : '0'
      this.elementData.close_syphilis =
        medical_follow_syphilis == 'NONE' ? '1' : '0'
      if (
        medical_follow_vih == 'NONE' &&
        medical_follow_vhc == 'NONE' &&
        medical_follow_syphilis == 'NONE'
      ) {
        this.elementData.follow_date_1 = null
        this.elementData.follow_date_2 = null
        this.elementData.follow_date_3 = null
        this.elementData.follow_date_4 = null
      }
      if (
        medical_follow_vih == 'NONE' &&
        medical_follow_vhc == 'NONE' &&
        medical_follow_syphilis == 'NONE' &&
        vaccine_hepatitis_b == 'NONE'
      ) {
        this.elementData.consult_case_status_key = 'CLOSED'
      } else {
        this.elementData.consult_case_status_key = 'FOLLOW'
      }

      const accident_date = moment(this.elementData.accident_date, 'DD/MM/YYYY')
      if (
        medical_follow_vih == 'FOLLOW_1' ||
        medical_follow_vhc == 'FOLLOW_1' ||
        medical_follow_syphilis == 'FOLLOW_1'
      ) {
        this.elementData.follow_date_1 = accident_date
          .clone()
          .add(6, 'weeks')
          .toDate()
        this.elementData.follow_date_2 = accident_date
          .clone()
          .add(3, 'months')
          .toDate()
        this.elementData.follow_date_3 = accident_date
          .clone()
          .add(6, 'months')
          .toDate()
        this.elementData.follow_date_4 = null
      }

      if (
        medical_follow_vih == 'FOLLOW_2' ||
        medical_follow_vhc == 'FOLLOW_2'
      ) {
        this.elementData.follow_date_1 = accident_date
          .clone()
          .add(6, 'weeks')
          .toDate()
        this.elementData.follow_date_2 = accident_date
          .clone()
          .add(3, 'months')
          .toDate()
        this.elementData.follow_date_3 = accident_date
          .clone()
          .add(6, 'months')
          .toDate()
        this.elementData.follow_date_4 = accident_date
          .clone()
          .add(1, 'years')
          .toDate()
      }
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}
</style>
